import {Divider, Grid, Skeleton, Box} from "@mui/material";
import { useTrail, animated, a, Spring } from '@react-spring/web'
import TrackVisibility from 'react-on-screen';
import { useState, useEffect, useRef } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { WORK_GRID } from './portfolio.js'
import Drawer from "./Drawer";
import {useSpring} from "react-spring";

const Work = () => {
    const [open, setOpen] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const grid = Object.values(WORK_GRID);
    const [animated, setAnimated] = useState(true);

    const trail = useTrail(1, {
        config: { mass: 50, tension: 3000, friction: 1000 },
        delay: 300,
        opacity: animated ? 1 : 0,
        y: animated ? 0 : 40,
        height: animated ? 85 : 0,
        from: { opacity: 0, y: 40, height: 0 },
    });

    const toggleDrawer = (project, newOpen) => () => {
        setOpen(newOpen);
        setCurrentProject(project);
    };

    return (
        <>
            <TrackVisibility>
                {({ isVisible }) => {
                    setAnimated(isVisible);
                    return (
                        <div key={Math.floor(Math.random() * 1000)} className="trails-wrapper">
                            {trail.map(({ height, ...style }, index) => (
                                <a.div key={index} className="trailsText" style={style}>
                                    <a.div  style={{ height}}>Work</a.div>
                                </a.div>
                            ))}
                        </div>
                    )
                }}
            </TrackVisibility>
            <Grid container alignItems="stretch">
                {grid?.map((item, i) => {
                    const divider = i%2 === 0;
                    return (
                        <>
                            <Grid key={item.id} item sm={12} md={6} className="tile flex">
                                <TrackVisibility partialVisibility once>
                                    {({ isVisible }) => (
                                        <a.div key={Math.floor(Math.random() * 1000)} className="tile-fade" style={{ opacity: isVisible ? 1 : 0 }}>
                                            <div>
                                                <img
                                                    src={item.image}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    onClick={toggleDrawer(item, true)}
                                                />
                                            </div>
                                            <h4 onClick={toggleDrawer(item, true)}>{item.title}</h4>
                                            {/*<p>{item.body}</p>*/}
                                            {((i !== (grid.length - 1))) &&
                                                <Divider
                                                    className={((grid.length%2 === 0) && (i === (grid.length - 2)))? 'margin-y-l hide-md-up': 'margin-y-l'}
                                                     sx={{ flexGrow: 1}} />
                                            }
                                    </a.div>
                                    )}
                                </TrackVisibility>
                            </Grid>
                            <Divider
                                className="hide-md"
                                orientation="vertical"
                                flexItem
                                sx={{ mr: "-1px", display: divider? 'flex': 'none'}}
                            />
                        </>

                    )
                    })
                }
            </Grid>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(null, false)}
                onOpen={toggleDrawer(currentProject, true)}
                PaperProps={{
                    sx: {
                        height: '90vh',
                        borderTopLeftRadius: 30,
                        borderTopRightRadius: 38,
                    }
                }}
            >
                <Box
                    sx={{
                        borderTopLeftRadius: 30,
                        borderTopRightRadius: 38,
                    }}
                >
                {currentProject &&
                    <Drawer
                        project={currentProject}
                        setOpen={setOpen}
                    />
                }
                </Box>

            </SwipeableDrawer>
        </>
    );
};

export default Work;
